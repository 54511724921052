<template>
  <v-card class="elevation-0">
    <div id="mykadSpace" />
  </v-card>
</template>

<script>
import formField from '@/mixins/form-field'
import security from '@/security'
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import Webcam from '@uppy/webcam'
import XHRUpload from '@uppy/xhr-upload'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/webcam/dist/style.css'

export default {
  mixins: [formField],
  data () {
    return {
      filename: null,
      files: null,
      uppy: null,
      uploadMyKadDialog: false,
    }
  },
  mounted: function () {
    this.uppy = new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxFileSize: 2000000,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*'],
      },
      locale: {
        strings: {
          youCanOnlyUploadX: {
            0: 'You can only upload %{smart_count} file',
            1: 'You can only upload %{smart_count} files',
          },
          youHaveToAtLeastSelectX: {
            0: 'You have to select at least %{smart_count} file',
            1: 'You have to select at least %{smart_count} files',
          },
          exceedsSize: 'This file exceeds maximum allowed size of',
          youCanOnlyUploadFileTypes: 'You can only upload:',
          uppyServerError: 'Connection with OneSYS failed',
        },
      },
      meta: {
        dealer: security.me.dealerId(),
      },
      note: 'MyKad front image only, 1 file, up to 2MB',
      replaceTargetContent: true,
    }).use(Dashboard, {
      trigger: '.UppyModalOpenerBtn',
      inline: true,
      target: '#mykadSpace',
      proudlyDisplayPoweredByUppy: false,
      replaceTargetContent: true,
      showProgressDetails: true,
      // note: 'Images and video only, 2–3 files, up to 1 MB',
      height: 300,
      metaFields: [
        { id: 'name', name: 'Name on MyKad', placeholder: '' },
        { id: 'mykad', name: 'MyKad Number', placeholder: '' },
        { id: 'address', name: 'Address', placeholder: '' },
      ],
      browserBackButtonClose: true,
      // disableInformer: true,
      locale: {
        strings: {
          dropPasteImport: 'Drop MyKad file here, paste or import from',
          importFrom: 'Import MyKad from',
        },
      },
      disableStatusBar: true,
      // hideUploadButton: true,
      // hideRetryButton: true,
      // hidePauseResumeButton: true
      // maxHeight: 350,
      // note: 'MyKad',
      // semiTransparent: true
    // }).use(DragDrop, {
    //  target: 'Dashboard'
    }).use(Webcam, {
      target: Dashboard,
      title: 'Camera',
      mirror: true,
      facingMode: 'environment',
      locale: {
        strings: {
          smile: 'MyKad',
        },
      },
      modes: ['picture'],
    }).use(XHRUpload, {
      endpoint: 'https://onesys.onexox.my/api/uploadMyKad.php',
      formData: true,
    })

    this.uppy.on('complete', result => {
      console.log('successful files:', result.successful)
      console.log('failed files:', result.failed)
    })
  },
  methods: {
    getFormData (files) {
      const forms = []
      for (const file of files) {
        const form = new FormData()
        form.append('data', file, file.name)
        forms.push(form)
      }
      return forms
    },
    onFocus () {
      if (!this.disabled) {
        this.$refs.fileInput.click()
      }
    },
    onFileChange ($event) {
      const files = $event.target.files || $event.dataTransfer.files
      const form = this.getFormData(files)
      if (files) {
        if (files.length > 0) {
          this.filename = [...files].map(file => file.name).join(', ')
        } else {
          this.filename = null
        }
      } else {
        this.filename = $event.target.value.split('\\').pop()
      }
      this.$emit('input', this.filename)
      this.$emit('update:form-data', form)
    },
  },
}
</script>

<style>
.uppy-Dashboard-inner {
    position: relative;
    background-color: #212121 !important;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: none;
    border: 1px solid hsla(0,0%,58%,.2);
}

.uppy-DashboardFileCard {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 1005 !important;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1) !important;
  background-color: #000 !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.uppy-DashboardContent-bar {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  -ms-flex-pack: justify !important;
      justify-content: space-between !important;
  height: 40px !important;
  width: 100% !important;
  border-bottom: 1px solid rgba(147, 147, 147, 0.3) !important;
  z-index: 1004 !important;
  background-color: #212121 !important;
  padding: 0 10px !important; }
  .uppy-size--md .uppy-DashboardContent-bar {
    height: 50px !important;
    padding: 0 15px !important; }

.uppy-Dashboard-actions {
  height: 55px !important;
  border-top: 1px solid rgba(147, 147, 147, 0.3) !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
      align-items: center !important;
  padding: 0 15px !important;
  background-color: #666 !important; }

.uppy-Dashboard-AddFilesPanel {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  background: #212121 !important;
  background: linear-gradient(0deg, #212121 35%, rgba(50, 50, 50, 0.85) 100%) !important;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15) !important;
  overflow: hidden !important;
  z-index: 1005 !important;
  border-radius: 5px !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column !important;
      flex-direction: column !important; }

/*

.uppy-Dashboard-dropFilesTitle {
    text-align: center;
    font-size: 15px;
    line-height: 1.45;
    font-weight: 400;
    color: #fff;
    margin: 0;
    margin-top: 25px;
}

.uppy-Dashboard-bgIcon .UppyIcon {
    width: 100%;
    height: 80px;
    fill: none;
    stroke: #fff;
}

.uppy-DashboardTab-btn {
    width: 100%;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    -webkit-appearance: none;
    transition: all .3s;
    color: #fff;
}

.uppy-DashboardContent-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 100%;
    border-bottom: 1px solid hsla(0,0%,58%,.3);
    z-index: 1004;
    background-color: #000;
}

.uppy-StatusBar:not([aria-hidden="true"]).is-waiting {
    background-color: #212121;
    height: 65px;
    line-height: 65px;
}
*/
</style>
